<template>
  <div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loggedIn: false,
    };
  },
  created() {
    this.loggedIn = this.$store.getters.loggedIn;

    if (!this.loggedIn) {
      this.$router.replace('/login');
    } else {
      this.$router.replace('/vchat');
    }
  },
};
</script>
