import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Unicon from 'vue-unicons';
import Toaster from '@meforma/vue-toaster';

// https://libraries.io/npm/@imengyu%2Fvue3-context-menu
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css';
import ContextMenu from '@imengyu/vue3-context-menu';

import { 
  uniAirplay,
  uniBars,
  uniCalling,
  uniCommentAltLines, 
  uniDraggabledots,
  uniHeadphones,
  uniHeadphoneSlash, 
  uniMicrophone,
  uniMicrophoneSlash,
  uniMinusSquare,
  uniPhone,
  uniSelfie,
  uniShield,
  uniShieldSlash,
  uniSpinnerAlt,
  uniStar,
  uniTimesCircle,
  uniUsersAlt,
  uniVideo,
  uniVideoSlash,
  uniVolume,
  uniVolumeMute
} from 'vue-unicons/dist/icons';

Unicon.add([
  uniAirplay,
  uniBars,
  uniCalling,
  uniCommentAltLines,
  uniDraggabledots,
  uniHeadphones,
  uniHeadphoneSlash, 
  uniMicrophone,
  uniMicrophoneSlash,
  uniMinusSquare,
  uniPhone,
  uniSelfie,
  uniShield,
  uniShieldSlash,
  uniSpinnerAlt,
  uniStar,
  uniTimesCircle,
  uniUsersAlt,
  uniVideo,
  uniVideoSlash,
  uniVolume,
  uniVolumeMute
]);

const app = createApp(App);

app.use(store);
app.use(router);
app.use(Unicon);
app.use(Toaster);
app.use(ContextMenu);

app.mount('#app');
