import { createStore } from 'vuex'

export default createStore({
  state() {
    return {
      loggedIn: false,
      vcUserName: '',
    };
  },
  getters: {
    loggedIn(state) {
      return state.loggedIn;
    },
    vcUserName(state) {
      return state.vcUserName;
    },
  },
  mutations: {
    setLoggedIn(state, loggedIn) {
      state.loggedIn = loggedIn;
    },
    setVcUserName(state, vcUserName) {
      state.vcUserName = vcUserName;
    },
  },
  actions: {
    setLoggedIn(context, loggedIn) {
      context.commit('setLoggedIn', loggedIn);
    },
    setVcUserName(context, setVcUserName) {
      context.commit('setVcUserName', setVcUserName);
    },
    logout(context) {
      context.commit('setVcUserName', '');
      context.commit('setLoggedIn', false);
    },
  },
  modules: {
  }
})
