<template>
  <router-view/>
</template>

<script>
const defaultSettings = {
  htmlTitle: 'Esdikay Zoom',
};

export default {
  created() {
    document.title = defaultSettings.htmlTitle;
  },
};
</script>

<style>
@import "@/assets/fonts.css";
body {
  background-color: #171412;
  font-family: "cuprum";
}

input {
  font-family: "cuprum";
  text-align: center;
}
button {
  font-family: "cuprum";
}

</style>
